<template>
  <el-dialog :visible.sync="isShow" :destroy-on-close="true" :close-on-click-modal="true" :modal-append-to-body="false">
    <div class="content-box">
      <slot name="title-cover">
        <img :src="icon" class="cover" v-if="icon">
      </slot>
      <div class="title" :class="status === 0 ? 'title-error' : ''" :data-text="$t(title)">{{ $t(title) }}</div>
      <slot>
        <img :src="icon" class="cover" v-if="icon">
      </slot>
      <div class="tips">{{ $t(msg) }}</div>
      <slot name="button">
        <el-button class="button_fullwidth five-button" :class="status ? '' : 'five-button-green'" size="mini"
          @click="submit" :loading="btnLoading">{{ $t(bthText)
          }}</el-button>
      </slot>
    </div>
    <div class="close-icon" @click="isShow = false">
      <img :src="require('@/assets/images/five-close-icon.png')" alt="">
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      msg: "",
      callBack: null,
      bthText: "",
      title: "",
      btnLoading: false,
      icon: "",
      status: 1
    }
  },
  methods: {
    show({ msg, callBack, bthText, icon, title, status }) {
      this.msg = msg
      this.icon = icon
      this.title = title
      this.callBack = callBack
      this.bthText = bthText
      this.isShow = true
      if (status !== undefined) {
        this.status = status
      }
    },
    async submit() {
      this.btnLoading = true
      this.callBack && await this.callBack()
      this.btnLoading = false
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  box-shadow: none;
  max-width: calc(100vw - 40px);
  min-width: 300px;
  width: 350px;
  background-color: transparent;
  background-image: url(~@/assets/images/five-tip-pop-bg.png);
  background-size: 100% 100%;
  margin: 0 auto 50px !important;
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    color: #FFF;
  }
}

.content-box {
  padding: 80px 26px 36px 26px;
  min-height: 350px;
}

.title {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  color: #A6400E;
  position: relative;

  &::before {
    width: 100%;
    content: attr(data-text);
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    background: linear-gradient(12deg, #EA35CE 0%, #FA6EA4 0%, #FDE878 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translate(-1px, -3px);
  }

  &.title-error {
    color: #000;

    &::before {
      background: linear-gradient(12deg, #E2E2E2 0%, #ABABAB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}


.tips {
  text-align: center;
  font-size: 12px;
}

.five-button {
  padding: 10px 15px;
  margin-top: 30px;
}

.five-button-green {
  color: #000000 !important;
  background: linear-gradient(13deg, #42EB85, #3EF7CF);
  box-shadow: 0px 1px 0px 0px #26A160;
}

.close-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);

  @media (max-width:540px) {
    width: 27px;
    height: 27px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>